import React from "react";
import Helmet from "react-helmet";
import CibilScore from "../../components/CibilScore/CibilScore";

import AboutUs from "../../components/AboutUs/AboutUs";
import ApplyLoanCard from "../../components/ApplyLoanCard/ApplyLoanCard";
import Faq from "../../components/Faq/Faq";
import Numbers from "../../components/Numbers/Numbers";
import PersonalLoan from "../../components/PersonalLoan/PersonalLoan";
//import PressRelease from "../../components/PressRelease/PressRelease";
import PrivilegeLoan from "../../components/PrivilegeLoan/PrivilegeLoan";
import Rating from "../../components/Rating/Rating";
import StepsForInstantLoan from "../../components/StepsForInstantLoan/StepsForInstantLoan";

// const AboutUs = lazy(() => import("../../components/AboutUs/AboutUs"));
// const ApplyLoanCard = lazy(() =>
//   import("../../components/ApplyLoanCard/ApplyLoanCard")
// );
// const Faq = lazy(() => import("../../components/Faq/Faq"));
// const Numbers = lazy(() => import("../../components/Numbers/Numbers"));
// const PersonalLoan = lazy(() =>
//   import("../../components/PersonalLoan/PersonalLoan")
// );
// // const PressRelease = lazy(() =>
// //   import("../../components/PressRelease/PressRelease")
// // );
// const PrivilegeLoan = lazy(() =>
//   import("../../components/PrivilegeLoan/PrivilegeLoan")
// );
// const Rating = lazy(() => import("../../components/Rating/Rating"));
// const StepsForInstantLoan = lazy(() =>
//   import("../../components/StepsForInstantLoan/StepsForInstantLoan")
// );

const Home = ({ mobile, open, setToast }) => {
  //let fallback = "Loading...";
  return (
    <>
      <Helmet>
        <title>Best Loan Company in Delhi NCR providing instant Personal Loans approval | Payday Loan Delhi</title>
        <meta
          name="description"
          content="Looking for quick financial solutions? The best loan company in Delhi NCR offering instant approval on personal loans and payday loans with hassle-free processing."/>
      </Helmet>
      <div>
        <CibilScore setToast={setToast} />

        <PersonalLoan open={open} />
        <PrivilegeLoan open={open} />
        <StepsForInstantLoan open={open} />
        <Numbers open={open} />
        <Rating mobile={mobile} open={open} />
        <AboutUs open={open} />
        {/* <PressRelease /> */}
        <ApplyLoanCard open={open} />
        <Faq mt={{ marginTop: 60 }} />
      </div>
    </>
  );
};

export default Home;
